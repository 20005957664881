<template>
  <div>
    <b-container>
      <b-card header="Detail">
        <div class="loader text-center"></div>
      </b-card>
    </b-container>
    <form id="loading">
      <input class="form-control" type="hidden" name="mid">
      <input class="form-control" type="hidden" name="txntype">
      <input class="form-control" type="hidden" name="lang">
      <input class="form-control" type="hidden" name="reference">
      <input class="form-control" type="hidden" name="product">
      <input class="form-control" type="hidden" name="returnurl">
      <input class="form-control" type="hidden" name="statusurl">
      <input class="form-control" type="hidden" name="fgkey">
      <input class="form-control" type="hidden" name="param1">
      <input class="form-control" type="hidden" name="param2">
      <input class="form-control" type="hidden" name="name">
      <input class="form-control" type="hidden" name="cur">
      <input class="form-control" type="hidden" name="amt">
      <input class="form-control" type="hidden" name="shop">
      <input class="form-control" type="hidden" name="email">
      <input class="form-control" type="hidden" name="paymentmethods">
    </form>
  </div>
</template>

<script>
export default {
  name: "McpamynetLoading",
  beforeMount() {
    const query = this.$route.query
    if (query.token) {
      this.token = query.token
      this.get_token()
    } else {
      this.$swal('Not have token', '', 'error')
    }
  },
  data() {
    return {
      token: ''
    }
  },
  methods: {
    async get_token() {
      try {
        const result = await this.axios.get('/paymentpage/mcpayment/checkorder', {params: {id: this.token}})
        if (result.data) {
          if (result.data.type_payment === 'CARD') {
            this.redirect_paypage()
          } else {
            this.dopayment()
          }
        }
      } catch (e) {
        console.log(e)
        this.$swal('Error', e.response && e.response.data ? e.response.data.message : '', 'error')
      }
    },
    async redirect_paypage() {
      try {
        const result = await this.axios.get('/paymentpage/mcpayment/paymentpage', {params: {id: this.token}})
        if (result.data) {
          window.location.href = result.data.redirect_url
        }
      } catch (e) {
        console.log(e)
        this.$swal('Error', e.response && e.response.data ? e.response.data.message : '', 'error')
      }
    },
    async dopayment() {
      try {
        const result = await this.axios.get('/paymentpage/mcpayment/dopayment', {params: {id: this.token}})
        if (result.data) {
          this.submit_from(result.data)
        }
      } catch (e) {
        console.log(e)
        this.$swal('Error', e.response && e.response.data ? e.response.data.message : '', 'error')
      }
    },
    submit_from(data) {
      var form = document.getElementById('loading')
      form.action = data.env === 'LIVE' ? 'https://map.mcpayment.net/payment/dopayment' : data.env === 'TEST' ? 'https://map.sandbox.mcpayment.net/payment/dopayment' : '#'
      form.method = 'POST'
      form.elements['mid'].value = data.mid;
      form.elements['txntype'].value = 'SALE'
      form.elements['lang'].value = data.lang ? data.lang : 'EN';
      form.elements['reference'].value = data.id;
      form.elements['product'].value = 'Topup';
      form.elements['returnurl'].value = data.return_url;
      form.elements['statusurl'].value = data.status_url;
      form.elements['fgkey'].value = data.fgkey;
      form.elements['param1'].value = data.id;
      form.elements['param2'].value = data.orderid;
      form.elements['cur'].value = data.currency;
      form.elements['amt'].value = data.amount;
      form.elements['shop'].value = data.shop;
      form.elements['email'].value = data.email;
      form.elements['paymentmethods'].value = data.payment.toLowerCase();
      form.submit()
    }
  }
}
</script>

<style scoped>
.loader {
  margin: auto;
  text-align: center;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
